import Model from "../models/Model";

export const getValue = (entity: Model, name: string): any => {
  if (!(name in entity)) {
    console.error(`${name} not found in ${entity}`)
  }

  return entity[name as keyof typeof entity]
};

export const getErrorMessage = (entity: Model, name: string): { error: boolean, message: string } =>  {
  // const errVar = `error${name.replace(/\./g, '')}`
  // return entity[errVar as keyof typeof entity];

  return {
    error: false,
    message: ''
  }
};

export const validateStr = (entity: Model, name: string, value: string) => {
  try {
    const errVar = `error${name.replace(/\./g, '')}`
    const errorResult = entity.validation[errVar](value);
    entity[errVar as keyof typeof entity] = errorResult;
    return errorResult;
  } catch {
    console.error(`valdation for ${name} is not set`)
  }
};

export const validateInt = (entity: Model, name: string, value: number) => {
  // return handler(props, 'validate', value);
};

export const setInt = (entity: Model, name: string, value: number) => {
  // return handler(props, 'update', value);
};

export const setStr = (entity: Model, name: string, value: string) => {
    entity[name as keyof typeof entity] = value;
};
