import { observer } from "mobx-react";

const FormattedMessage = observer(() => {
  return (
    <>
      FormattedMessage
    </>
  )
})

export default FormattedMessage;
