import { observer } from 'mobx-react-lite';
import Model from '../models/Model';
import { 
  getValue,
  getErrorMessage,
  validateInt,
  validateStr,
  setInt,
  setStr,
} from './IO';

interface props {
  entity: Model,
  name: string
  placeholder?: string,
  type?: string,
  className?: string,
  disableAutoFill?: boolean,
}

const Input = observer(({
    entity,
    name,
    placeholder = '',
    type = 'text',
    className = '',
    disableAutoFill = false,
}: props) => { 

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    switch(type) {
      case 'number':
        setInt(entity, name, parseInt(ev.target.value, 10))
        break;

      default:
        setStr(entity, name, ev.target.value);
        break;
    }
  };

  const onValidate = (ev: React.ChangeEvent<HTMLInputElement>) => {
    switch(type) {
      case 'number':
        validateInt(entity, name, parseInt(ev.target.value, 10))
        break;

      default:
        validateStr(entity, name, ev.target.value);
        break;
    }
  };

  const { error } = getErrorMessage(entity, name);
  const value = getValue(entity, name)

  return (
    <input 
      className={`${className} ${error && 'invalid'}`}
      type={type}
      onChange={onChange}
      value={value}
      onBlur={onValidate}
      placeholder={placeholder}
      autoComplete={disableAutoFill ? 'none' : ''}
    />
  )
})

export default Input;

