import { observer } from "mobx-react";

const Spinner = observer(() => {
  return (
    <>
    Spinner
    </>
  )
})

export default Spinner;
